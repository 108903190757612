<script setup lang="ts">
import { sentence } from '@/utils/Helpers';

const props = withDefaults(
  defineProps<{
    error?: string | string[];
    sign?: string;
    display?: 'single' | 'sentence' | 'list';
  }>(),
  {
    error: undefined,
    sign: undefined,
    display: 'sentence',
  }
);

const parsedError = computed(() => {
  if (!Array.isArray(props.error)) {
    return props.error;
  }

  switch (props.display) {
    case 'single':
      return props.error.length ? props.error[0] : '';
    case 'sentence':
      return sentence(props.error);
    case 'list':
      return props.error;
    default:
      return null;
  }
});

const isList = computed(() => Array.isArray(parsedError.value));
const hasError = computed(() => parsedError.value);
</script>
<template>
  <div v-if="hasError" class="error-message" role="alert">
    <div class="error-message__border v-messages">
      <div class="error-message__message v-messages__message">
        <template v-if="isList">
          <ul>
            <li v-for="(e, index) in parsedError" :key="index">
              {{ e }}
            </li>
          </ul>
        </template>
        <template v-else>
          <span v-if="sign" class="error-message__sign">{{ sign }}</span>
          {{ parsedError }}
        </template>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.error-message {
  display: block;
  color: inherit;

  &__border {
    opacity: 1 !important;
  }

  &__sign {
    margin-right: 5px;
  }

  &__message {
    -webkit-padding-start: 16px;
    padding-inline-start: 16px;
    -webkit-padding-end: 16px;
    padding-inline-end: 16px;
  }

  > ul,
  li {
    font-family: inherit;
    font-size: inherit;
  }

  > ul {
    margin: inherit;
    padding: inherit;
    color: inherit;
  }
}
</style>
